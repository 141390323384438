import { format } from "date-fns";

export const createImage = (key) => {
  return process.env.GATSBY_API_URL + key;
};

export const createMarkup = (content) => {
  return { __html: content };
};

export const formatDate = (date) => {
  const formattedDate = new Date(date).toDateString();
  return formattedDate;
};

export const formatDateOnly = (inputDateStr) => {
  console.log(inputDateStr, "adsasd");
  // Parse the input date string
  const parsedDate = new Date(inputDateStr);

  // Format the date
  const output = format(parsedDate, "dd MMMM yyyy");

  return output; // Output: "Aug 4 2022"
};

export const appendImage = (string) => {
  const element = document.querySelector(`.${string}`);
  const images = element?.getElementsByTagName("img");
  const appendString = process.env.GATSBY_API_URL; // Replace with the string you want to append

  for (let i = 0; i < images?.length; i++) {
    const img = images[i];
    const src = img.getAttribute("src");
    if (src && !src?.includes("https")) {
      img.setAttribute("src", appendString + src);
    }
  }
};
