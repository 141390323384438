import logo from "../../images/FestivalEventsLogo.png";
import animation1 from "../../images/animation-img1.webp";
import animation2 from "../../images/animation-img2.webp";
import animation3 from "../../images/animation-img3.webp";
import animation4 from "../../images/homepage-banner.webp";
import animation5 from "../../images/animation-img5.webp";
import animation6 from "../../images/animation-img6.webp";
import animation7 from "../../images/animation-img7.webp";
import screenlocation from "../../images/screen-location.webp";
import ffevent from "../../images/ff-events.webp";
import ticketAvalable from "../../images/ticket-availables.svg";
import sixtynine from "../../images/16x9.png";
import aboutimg from "../../images/about-bg.webp";
import weare1 from "../../images/weare-1.png";
import weare2 from "../../images/weare-2.png";
import weare3 from "../../images/weare-3.png";
import weare4 from "../../images/weare-4.png";
import weare5 from "../../images/weare-5.png";
//Login
import usericon from "../../images/userIcon.svg";
import emailicon from "../../images/email-icon.svg";
import passwordicon from "../../images/password-icon.svg";
import errorIcon from "../../images/error-icon.svg";
import loginbg from "../../images/loginbg.png";
import addressIcon from "../../images/add-icon.svg";
import backIcon from "../../images/backbtn.svg";
import logoutIcon from "../../images/log-out-icon.svg";
import editIcon from "../../images/editIcon.svg";
import editIcondis from "../../images/edit-icon-dis.svg";
import eyeIcon from "../../images/eyicon.svg";
import eyeIconcross from "../../images/eye-cross.svg";
import sucessIcon from "../../images/sucess.svg";

export const ImageConstants = {
  sixtynine,
  logo,
  animation1,
  animation2,
  animation3,
  animation4,
  animation5,
  animation6,
  animation7,
  screenlocation,
  ffevent,
  ticketAvalable,
  aboutimg,
  weare1,
  weare2,
  weare3,
  weare4,
  weare5,
  usericon,
  emailicon,
  passwordicon,
  errorIcon,
  loginbg,
  addressIcon,
  backIcon,
  logoutIcon,
  editIcon,
  eyeIcon,
  eyeIconcross,
  sucessIcon,
  editIcondis,
};
