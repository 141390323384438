import React from "react";
import { Helmet as GatsbyHelmet } from "react-helmet";

const Helmet = ({
  title,
  description,
  image = "https://s3.eu-west-3.amazonaws.com/razr.public/festival_og.jpeg",
  keywords,
  url = `${process.env.GATSBY_SITE_URL}`,
}) => {
  return (
    <GatsbyHelmet
      htmlAttributes={{
        lang: "en",
      }}
      script={[
        {
          src: "https://consent.cookiefirst.com/sites/festivalevents.lu-98b4b53f-8ba4-4d6d-acb7-46cfb27ab43e/consent.js",
          type: "text/javascript",
        },
      ]}
    >
      {/* <html lang="en" /> */}
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta property="og:locale" content="en_US" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
      />
      <meta property="og:image" itemProp="image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:site_name" content="Festival Events" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      {/* <link
        rel="icon"
        type="image/png"
        href={ImageConstants.favicon}
        sizes="32x32"
      /> */}

      <script src="https://consent.cookiefirst.com/sites/festivalevents.lu-98b4b53f-8ba4-4d6d-acb7-46cfb27ab43e/consent.js"></script>
    </GatsbyHelmet>
  );
};

export default Helmet;
