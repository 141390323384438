import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import routes from "../components/constants/routes";
import "../styles/footer.scss";
import axios from "axios";
import { apiRoutes } from "../constants/apiRoutes";
import { createImage } from "../utils";
import { Placeholder } from "react-bootstrap";

export default function Footer() {
  const [footerData, setFooterData] = useState([]);
  const [pathname, setPathName] = useState(undefined);

  const fetchFooter = async () => {
    try {
      const { data } = await axios.get(apiRoutes.Footer);
      if (data) setFooterData(data.data);
    } catch (error) {}
  };
  console.log(footerData);
  useEffect(() => {
    const tempPath =
      typeof window !== "undefined" ? window.location.href : undefined;
    setPathName(tempPath);

    fetchFooter();
  }, []);
  return (
    <>
      <div className="footer mt-40">
        {footerData?.attributes ? (
          <div className="footer-container">
            {footerData?.attributes?.footer_logo?.data?.attributes?.url ? (
              <Link to="/">
                <img
                  src={createImage(
                    footerData?.attributes?.footer_logo?.data?.attributes?.url
                  )}
                  alt="footer logo"
                  width={"auto"}
                  height={"auto"}
                />
              </Link>
            ) : null}

            <div className="footer-nav">
              <ul>
                {footerData?.attributes?.footer_name?.map((el) => {
                  return (
                    <>
                      {!el.hide || !el.url ? (
                        <li key={el.id}>
                          <Link
                            className={`hover-target ${
                              pathname === process.env.GATSBY_SITE_URL &&
                              el.url === "/"
                                ? "active"
                                : el.url !== "/" && pathname?.includes(el.url)
                                ? "active"
                                : ""
                            }`}
                            to={el.url}
                          >
                            {el.title}
                          </Link>
                        </li>
                      ) : null}
                    </>
                  );
                })}
              </ul>
            </div>
            <div className="footer-social">
              <ul>
                {footerData?.attributes?.social_icons?.map((icon) =>
                  icon.url ? (
                    <li>
                      <Link className="hover-target" to={icon.url}>
                        <img
                          src={createImage(
                            icon.social_icon.data.attributes.url
                          )}
                          alt={icon?.title}
                          width={"auto"}
                          height={"auto"}
                        />
                      </Link>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        ) : (
          <Placeholder animation="glow" className="footeranimation">
            <Placeholder />
          </Placeholder>
        )}
      </div>
    </>
  );
}
