export const apiRoutes = {
  homepage:
    process.env.GATSBY_API_URL +
    "/api/home?populate=herosection.background_image,herosection.trusted_images,home_about_section.carousel_image,home_luxembourg.image",

  // film festival
  aboutFilmFestival:
    process.env.GATSBY_API_URL +
    "/api/about-flim-festival?populate=about_carousel.carousel_image,sponsor_image,about_screening_event",
  filmFestivalScreening:
    process.env.GATSBY_API_URL + "/api/screening?populate=image",

  news: (pagination = false, pageSize = 5, type = undefined) => {
    let url = `/api/news?populate=image,type`;
    // FF, DL About => pagination, type
    if (type && pagination) {
      url = `/api/news?populate=image,type&filters[type][newstype][$contains]=${type}&pagination[pageSize]=${pageSize}&sort[0]=date:desc`;
    }
    // FF, DL News => type
    else if (type && !pagination) {
      url = `/api/news?populate=image,type&filters[type][newstype][$contains]=${type}&sort[0]=date:desc`;
    }
    // Home => none
    else if (!type && pagination) {
      url = `/api/news?populate=image,type&sort[0]=date:desc&pagination[pageSize]=${pageSize}`;
    }

    return process.env.GATSBY_API_URL + url;
  },

  filmFestivalCurrentSeason:
    process.env.GATSBY_API_URL +
    "/api/current-season?populate=past_season_ff.description,past_season_ff.winnerBox,past_season_ff.sponsor_image,past_season_ff.flims.image,past_season_ff.winnerBox.flim_image,past_season_ff.winnerBox.wining_type",
  filmFestivalPastSeasons:
    process.env.GATSBY_API_URL +
    "/api/past-seasons?populate=description,flims.image&sort=sort_number:asc",

  // discover lux
  discoverluxAbout:
    process.env.GATSBY_API_URL +
    "/api/about-discover-luxembourg?populate=about_event.carousel_image,about_event_history.carousel_image,about_luxembourg,Results,about_event.image,event_date_image",
  discoverluxPastEvent:
    process.env.GATSBY_API_URL +
    "/api/past-events?populate=event_image,Results,event_partners.sponsor_image,station_sponsors.sponsor_image,sponsors_media_patners.sponsor_image,charity.sponsor_image&sort=sort_number:asc",
  discoverluxCurrEvent:
    process.env.GATSBY_API_URL +
    "/api/current-event?populate=past_event_dl,past_event_dl.event_partners.sponsor_image,past_event_dl.station_sponsors.sponsor_image,past_event_dl.sponsors_media_patners.sponsor_image,past_event_dl.charity.sponsor_image",

  // general
  About:
    process.env.GATSBY_API_URL +
    "/api/about-us?populate=a_prospos.image,notre_vision_section.icon,nos_fondateurs.details.image,nos_fondateurs.details.icon,nos_valeurs.nos_valeurs_description.icon,Lequipe.details.image,options,nos_valeurs_description.icon,banner_image,success_years_.description.image",
  header: process.env.GATSBY_API_URL + "/api/header?populate=*",
  Contactus:
    process.env.GATSBY_API_URL +
    "/api/contact?populate=how_to_contact.image,locations.location_details.image",
  Footer:
    process.env.GATSBY_API_URL +
    "/api/footer?populate=social_icons.social_icon,footer_logo,footer_name",
  register: process.env.GATSBY_API_URL + "/api/registration?populate=*",

  terms: process.env.GATSBY_API_URL + "/api/term-condition?populate=*",
  privacy: process.env.GATSBY_API_URL + "/api/privacy-policy?populate=*",
  cookie: process.env.GATSBY_API_URL + "/api/cookie-policy?populate=*",

  //About us page
  Aboutuspage: process.env.GATSBY_API_URL + "/api/about-us?populate=*",
  Aboutustems: process.env.GATSBY_API_URL + "/api/teams?populate=*",
};
