import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
import "../styles/home.scss";
import Helmet from "./Helmet";

export default function Layout({ children }) {
  return (
    <>
      <div className="container-fluid layout">
        <div className="row">
          <div className="col-12 p-0">
            <Header />
            <div className="body">{children}</div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
