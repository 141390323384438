import { Link } from "gatsby";
import React from "react";
import "../styles/header.scss";
import routes from "./constants/routes";
import { useState, useEffect } from "react";
import { ImageConstants } from "./constants/Imageconstants";
import { Cookies } from "react-cookie";

export default function Header() {
  const cookies = new Cookies();
  const [pathname, setPathName] = useState(undefined);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });

    const tempPath =
      typeof window !== "undefined" ? window.location.href : undefined;
    setPathName(tempPath);
  }, []);

  const hideStyles = () => {
    const body = document.querySelector("body");
    body.style = null;
  };
  const [hidetabsmenu, setHidetabsmenu] = useState(false);
  const handletab = () => {
    const login = cookies.get("login");
    if (login) {
      setHidetabsmenu(true);
    } else {
      setHidetabsmenu(false);
    }
  };
  useEffect(() => {
    handletab();
  }, []);

  return (
    <>
      <div className="header">
        <div
          className={
            scroll ? "header-container stickyheader" : "header-container"
          }
        >
          <nav className="navbar navbar-expand-sm navbar-dark mobile-hide">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul
                className={`nav nav-tabs pt-10 navbar-nav me-auto ${
                  pathname === process.env.GATSBY_SITE_URL && !scroll
                    ? "white-color"
                    : "black-color"
                } `}
                id="myTab"
              >
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      // pathname === process.env.GATSBY_SITE_URL + "about"
                      //   ? "active"
                      //   : ""
                      pathname?.includes(routes.About) ? "active" : ""
                    }`}
                    to={routes.About}
                    aria-label="About"
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className={`nav-link ${
                      pathname?.includes(routes.Filmfestival) ? "active" : ""
                    }`}
                    to={routes.FFAbout}
                  >
                    Film Festivals
                  </Link>
                  <ul className="dropdown-menu font-12">
                    <li>
                      <Link className="dropdown-item" to={routes.FFAbout}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={routes.FFScreenings}>
                        Schedule
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to={routes.FFCurrentSeason}
                      >
                        Current Screenings
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="color-black dropdown-item font-12"
                        to={routes.FFPastSeason}
                      >
                        Past Screenings
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={routes.FFNews}>
                        News
                      </Link>
                    </li>
                    {!hidetabsmenu ? (
                      <li>
                        <Link className="dropdown-item" to={routes.FFRegister}>
                          Sign Up / Sign In
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link className="dropdown-item" to={routes.FFMyprofile}>
                          My profile
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className="logo-header nav-item">
                  <Link to={routes.Home}>
                    <img
                      src={ImageConstants.logo}
                      alt="header logo"
                      width={100}
                      height={100}
                    />
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className={`nav-link ${
                      pathname?.includes(routes.Discoverluxembourg)
                        ? "active"
                        : ""
                    }`}
                    to={routes.DLAbout}
                  >
                    Discover Luxembourg
                  </Link>
                  <ul className="dropdown-menu font-12">
                    <li>
                      <Link className="dropdown-item" to={routes.DLAbout}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to={routes.DLCurrentEvent}
                      >
                        Current Event
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={routes.DLPastEvents}>
                        Past Events
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to={routes.DLNews}>
                        News
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={routes.DLRegister}>
                        Register
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      pathname?.includes(routes.contact) ? "active" : ""
                    }`}
                    to={routes.contact}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </nav>

          <div className="navbar-toggle mobile-show">
            <Link to="/" onClick={hideStyles}>
              <img
                src="/FestivalEventsLogo.png"
                className="mob-logo"
                width={"auto"}
                height={"auto"}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <span className="navbar-toggler-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="48"
                  viewBox="0 -960 960 960"
                  width="48"
                >
                  <path d="M120-240v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" />
                </svg>
              </span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <Link to="/">
                  <img
                    src="/FestivalEventsLogo.png"
                    className="offcanvas-mob-logo"
                    width={100}
                    height={100}
                  />
                </Link>
                <button
                  type="button"
                  className="text-reset"
                  data-bs-dismiss="offcanvas"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="35"
                    viewBox="0 -960 960 960"
                    width="35"
                  >
                    <path d="m330-288 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
                  </svg>
                </button>
              </div>
              <div className="offcanvas-body">
                <div className="accordion" id="accordionExample">
                  <div className="nav-about">
                    <p>
                      <Link
                        to={routes.About}
                        className={
                          // pathname === process.env.GATSBY_SITE_URL + "about"
                          //   ? "active"
                          //   : ""
                          pathname?.includes(routes.About) ? "active" : ""
                        }
                        onClick={hideStyles}
                      >
                        About Us
                      </Link>
                    </p>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={`accordion-button ${
                          pathname?.includes(routes.Filmfestival)
                            ? ""
                            : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Film Festivals
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse collapse ${
                        pathname?.includes(routes.Filmfestival) ? "show" : ""
                      }`}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.FFAbout) ? "active" : ""
                            }
                            onClick={hideStyles}
                            to={routes.FFAbout}
                          >
                            About
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.FFScreenings)
                                ? "active"
                                : ""
                            }
                            onClick={hideStyles}
                            to={routes.FFScreenings}
                          >
                            Schedule
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.FFCurrentSeason)
                                ? "active"
                                : ""
                            }
                            onClick={hideStyles}
                            to={routes.FFCurrentSeason}
                          >
                            Current Screenings
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.FFPastSeason)
                                ? "active"
                                : ""
                            }
                            onClick={hideStyles}
                            to={routes.FFPastSeason}
                          >
                            Past Screenings
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.FFNews) ? "active" : ""
                            }
                            onClick={hideStyles}
                            to={routes.FFNews}
                          >
                            News
                          </Link>
                        </p>

                        {!hidetabsmenu ? (
                          <p>
                            <Link
                              className={
                                pathname?.includes(routes.FFRegister)
                                  ? "active"
                                  : ""
                              }
                              onClick={hideStyles}
                              to={routes.FFRegister}
                            >
                              Sign Up / Sign In
                            </Link>
                          </p>
                        ) : (
                          <p>
                            <Link
                              className={
                                pathname?.includes(routes.FFMyprofile)
                                  ? "active"
                                  : ""
                              }
                              onClick={hideStyles}
                              to={routes.FFMyprofile}
                            >
                              My profile
                            </Link>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className={`accordion-button ${
                          pathname?.includes(routes.Discoverluxembourg)
                            ? ""
                            : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Discover Luxembourg
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={`accordion-collapse collapse ${
                        pathname?.includes(routes.Discoverluxembourg)
                          ? "show"
                          : ""
                      }`}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.DLAbout) ? "active" : ""
                            }
                            onClick={hideStyles}
                            to={routes.DLAbout}
                          >
                            About
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.DLCurrentEvent)
                                ? "active"
                                : ""
                            }
                            onClick={hideStyles}
                            to={routes.DLCurrentEvent}
                          >
                            Current Event
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.DLPastEvents)
                                ? "active"
                                : ""
                            }
                            onClick={hideStyles}
                            to={routes.DLPastEvents}
                          >
                            Past Events
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.DLNews) ? "active" : ""
                            }
                            onClick={hideStyles}
                            to={routes.DLNews}
                          >
                            News
                          </Link>
                        </p>
                        <p>
                          <Link
                            className={
                              pathname?.includes(routes.DLRegister)
                                ? "active"
                                : ""
                            }
                            onClick={hideStyles}
                            to={routes.DLRegister}
                          >
                            Register
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="nav-contact">
                    <p>
                      <Link
                        className={
                          pathname?.includes(routes.contact) ? "active" : ""
                        }
                        onClick={hideStyles}
                        to={routes.contact}
                      >
                        Contact
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
